<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()" :disabled="isSaving" type="button"
						class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right" to="/admin/DocTemplate/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">編號</label>
							<div class="col-sm-10">
								<input v-model="model.id" type="text" class="form-control" id="id" name="id" required
									readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="code" class="col-sm-2 col-form-label">模板代碼</label>
							<div class="col-sm-10">
								<input v-model="model.code" type="text" class="form-control" id="code" name="code"
									required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="title" class="col-sm-2 col-form-label">模板說明</label>
							<div class="col-sm-10">
								<input v-model="model.title" type="text" class="form-control" id="title" name="title"
									required />
							</div>
						</div>
						<div class="form-group row">
							<div class="input-group">
								<input type="file" class="form-control" name="file" id="doc" ref="file"
									@change="handleFileUpload(this)" capture />

							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="version" class="col-sm-2 col-form-label">版號</label>
							<div class="col-sm-10">
								<input v-model="model.version" type="text" class="form-control" id="version"
									name="version" required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="enable" class="col-sm-2 col-form-label">啟用</label>
							<div class="col-sm-10">
								<input v-model="model.enable" type="checkbox" class="form-control" id="enable"
									name="enable" value="1" />
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
import LayoutDiv from '@/components/LayoutDiv.vue';
import { getDocTemplate, postDocTemplate } from '@/api/DocTemplate.js'

export default {
	name: 'DocTemplateEdit',
	components: {
		LayoutDiv,
	},
	data() {
		return {
			model: {
				id: '',
				code: '',
				title: '',
				file: '',
				version: '',
				enable: '',

			},
			isSaving: false,
		};
	},
	created() {
		const id = this.$route.params.id;

		getDocTemplate(id).then(rep => {
			this.model = rep.data;
		});

	},
	methods: {
		handleSave() {
			if (this.isSaving == true) return;

			this.isSaving = true;
			postDocTemplate(this.model).then(rep => {

				this.isSaving = false;
				return rep;
			})
				.catch(error => {
					this.isSaving = false;
					return error
				});
		},
	},
};
</script>